<template>
  <div
    id="app"
    :class="[
      'route--' + view.toLowerCase(),
      isOnTop ? 'page--is-top' : 'page--is-scrolled',
      { 'route--article': articlePage },
    ]"
    :style="`--custom-background-color: ${customColor}; --custom-body-color: ${customBodyColor}`"
  >
    <div class="transition">
      <img class="transition__img" :src="logo.src || logo" />
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav--expanded': isExpanded },
        { 'nav--contact': contactPage },
        { 'nav--article': articlePage && isOnTop },
        { 'nav--residential': hasFlatfinderGoBack },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="0"
          :action="mainAction"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <!-- <transition mode="out-in" name="route"> -->
    <router-view />
    <!-- </transition> -->
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from './gdpr'
import { Capitalize } from '@/utils'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },

    hasFlatfinderGoBack() {
      if (!this.$route.params.subpage && !this.$route.params.id) return false

      if (this.$route.params.subpage) {
        return Boolean(
          this.posts
            .filter(p => {
              return ['rental-residential'].includes(this.$path('customFieldsConfig.0.source', p))
            })
            .find(p => p.slug == this.$route.params.subpage),
        )
      }
      return true
    },

    articlePage() {
      if (!this.$route.params.subpage) return false

      return Boolean(
        this.posts
          .filter(p => {
            return ['article'].includes(this.$path('customFieldsConfig.0.source', p))
          })
          .find(p => p.slug === this.$route.params.subpage),
      )
    },

    contactPage() {
      if (!this.mainSlug) return false

      return Boolean(
        this.posts
          .filter(p => {
            return ['rental-contact', 'about-contact'].includes(
              this.$path('customFieldsConfig.0.source', p),
            )
          })
          .find(p => p.slug === this.mainSlug),
      )
    },

    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    residentialId() {
      return this.$route.params.id || null
    },

    slug() {
      return this.$route.params.slug || null
    },
    mainSlug() {
      return this.$route.params.main || null
    },
    customColor() {
      if (!this.$path('customFields.custom-color.show-color', this.item)) return 'white;'
      return this.$path('customFields.custom-color.color', this.item) || 'white;'
    },
    customBodyColor() {
      if (!this.$path('customFields.custom-color.show-contrast-color', this.item)) return 'black;'
      return this.$path('customFields.custom-color.contrast-color', this.item) || 'black;'
    },

    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      return this.redirect('Project', { scrollTo: 0 })
    },

    getNavBarItems() {
      if (!this.posts.length) return []

      if (!this.slug && !this.residentialId)
        return this.posts.filter(item =>
          ['rental', 'about'].includes(this.$path('customFieldsConfig.0.source', item)),
        )

      if (
        this.slug ===
        this.posts.find(post => this.$path('customFieldsConfig.0.source', post) === 'about').slug
      )
        return this.posts.filter(item =>
          this.$path('customFieldsConfig.0.source', item).startsWith('about'),
        )

      return this.posts.filter(item =>
        this.$path('customFieldsConfig.0.source', item).startsWith('rental'),
      )
    },

    defaultItems() {
      return [
        ...this.getNavBarItems.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}

          if (!this.slug && !this.residentialId)
            return {
              label: Capitalize(i.slug.replace(/-/g, ' ')),
              exact: true,
              action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
              order: (this.$path('customFields.page-settings.navigation-order', i) || 1) * 10 - 5,
            }

          const post = this.getNavBarItems.find(item =>
            ['rental', 'about'].includes(this.$path('customFieldsConfig.0.source', item)),
          )

          const isMainPost =
            this.$path('customFieldsConfig.0.source', i) ===
            this.$path('customFieldsConfig.0.source', post)

          const action = isMainPost
            ? this.redirect(['Post'], { params: { slug: i.slug } })
            : this.redirect(['Main'], {
                params: { slug: post.slug, main: i.slug },
                forceRedirect: true,
              })

          const re = new RegExp(/\/(om-drammen-helsepark|utleie)\/(.*)/)

          return {
            label: i.title,
            exact: true,
            action: action,
            order: (this.$path('customFields.page-settings.navigation-order', i) || 1) * 10 - 5,
            class: `navigation-page ${
              this.$route.path.replace('/', '') === i.slug ||
              (this.$route.path.match(re) || [])[2] === i.slug
                ? 'elder__navigation-component--active'
                : null
            }`,
          }
        }),
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }

        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.promise = this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.page-has-no-cover:not(.page-meld-interesse):not(.page-kontakt) {
  margin-top: var(--nav-height);
}

.transition {
  @keyframes PageLoadTransition {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes hideAnimation {
    to {
      display: none;
      width: 0;
      height: 0;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary);
  position: fixed;
  height: 100vh;
  margin: 0;
  width: 100%;
  opacity: 1;
  z-index: 10000;

  animation: PageLoadTransition 0.9s 1.3s forwards, hideAnimation 0s forwards 3s;

  &__img {
    @keyframes PageLoadImage {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    max-width: 14vw;
    width: 100%;
    object-fit: contain;
    opacity: 0;
    animation: PageLoadImage 2s 0s forwards;
  }
}

.nav {
  height: 120px;
  position: absolute;
  background: transparent;
  box-shadow: none;

  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  transition: color 0.3s ease-in-out, height 0.3s ease-in-out, background 0.3s ease-in-out,
    backdrop-filter 0.3s ease-in-out, -webkit-backdrop-filter 0.3s ease-in-out,
    transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

  @include respond-below('tablet') {
    transition: none;
    width: unset;
    position: fixed;
    right: 0;
  }

  .container {
    height: 100%;
    max-width: initial;
    padding: initial;
  }

  &--residential {
    @include respond-below('phone') {
      top: unset;
      bottom: 0;
      height: 84px;
    }
  }

  &--contact {
    background: rgb(42, 57, 68);

    @include respond-below('tablet') {
      background: transparent;
    }
  }

  .page--is-top & {
    @include respond-above('tablet') {
      background: linear-gradient(to bottom, black -80%, transparent 100%);
    }
  }

  .route--subpage.page--is-top &,
  .page--is-scrolled & {
    @include respond-above('tablet') {
      position: fixed;
      height: var(--nav-height);
      background: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.8);
      backdrop-filter: blur(40px);
    }

    .elder__navigation-component:after {
      bottom: -26px !important;
    }

    &--expanded {
      height: 100%;
      transition: none;
    }
  }

  .elder__navigation {
    height: 100%;

    &-node {
      margin-left: 0;
    }

    &-wrapper {
      height: 100%;
    }

    &-component {
      color: $beige;
      font-weight: 400;
      cursor: pointer;
      border-radius: 10px;
      font-size: 18px;
      padding: 8px 22px;
    }

    &-logo {
      @include respond-below('tablet') {
        display: none !important;
      }
    }

    &-logo-image {
      margin: 0 2px;
      max-height: 60px !important;
      min-height: 60px !important;

      cursor: pointer;
    }

    &--responsive {
      .elder__navigation-bars {
        display: inline-flex;
        justify-content: center;
        padding: 14px;
        margin-right: 18px;
        margin-left: auto;
        background-color: var(--primary);
        border-radius: 100%;
        width: 60px;
        height: 60px;
        font-size: 1.4rem;
        color: $beige;
      }
    }
  }

  &--expanded {
    width: 100%;
    transition: none;
    padding-bottom: 1rem;
    background: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.9);
    backdrop-filter: blur(5px);
    height: 100%;

    .elder__navigation {
      height: 100%;

      &--responsive {
        .elder__navigation-bars {
          background-color: rgb(58, 78, 93);
        }
      }

      &-logo-image {
        @include respond-below('tablet') {
          display: initial;
        }
      }
    }

    .elder__navigation-actions {
      padding-top: 2rem;
      height: 85vh;
      display: flex !important;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;

      .elder__navigation-component {
        padding: 1rem 2.5rem;
        justify-content: center;
        font-size: 1.25rem;

        &:hover {
          background-color: rgb(81, 110, 131);
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
