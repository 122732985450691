var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'route--' + _vm.view.toLowerCase(),
    _vm.isOnTop ? 'page--is-top' : 'page--is-scrolled',
    { 'route--article': _vm.articlePage },
  ],style:(`--custom-background-color: ${_vm.customColor}; --custom-body-color: ${_vm.customBodyColor}`),attrs:{"id":"app"}},[_c('div',{staticClass:"transition"},[_c('img',{staticClass:"transition__img",attrs:{"src":_vm.logo.src || _vm.logo}})]),(_vm.$route.meta.navigation !== false)?_c('div',{staticClass:"nav",class:[
      `nav--${_vm.view.toLowerCase()}`,
      { [`nav--${_vm.view.toLowerCase()}-expanded`]: _vm.isExpanded },
      { 'nav--expanded': _vm.isExpanded },
      { 'nav--contact': _vm.contactPage },
      { 'nav--article': _vm.articlePage && _vm.isOnTop },
      { 'nav--residential': _vm.hasFlatfinderGoBack },
    ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"items":_vm.items,"title":_vm.projectName,"padding":"0","action":_vm.mainAction,"isOpen":_vm.isExpanded},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}}})],1)]):_vm._e(),_c('router-view'),(_vm.$route.meta.footer !== false)?_c('FooterComponent',{attrs:{"navigation":_vm.items,"cookie-manager":_vm.CM}}):_vm._e(),_c('AssetComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }